.aboutMe {
    display: flex;
    flex-grow: 1;
    margin: 0% 4%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.aboutMe h2 {
    margin-bottom: 3%;
}

.aboutMe p {
    margin-bottom: 1%;
}