.home,
.homeSmall {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    align-self: stretch;
    margin-top: 30px;
}

.home {
    gap: 50px;
}

.homeSmall {
    gap: 7px;
    margin-bottom: 7px;
}

.home button {
    margin: 30px;
}

.content {
    display: flex;
    padding: 0px 15px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex: 1 0 0;
    align-self: stretch;
}

.homeSmall .content {
    gap: 7px;
    padding: 0px 7px;
}
