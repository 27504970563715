.App,
.AppSmall {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.AppSmall p {
    font-size: var(--p1);
}

.AppSmall h1 {
    font-size: var(--h2);
}

.AppSmall h2 {
    font-size: var(--h3);
}
