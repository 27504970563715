.projects,
.projectsSmall {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    justify-content: space-between;
}

.projects {
    align-items: center;
    flex: 1;
}

.content {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;
}

.projectsSmall .content {
    margin-bottom: 30px;
}

.cards {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0% 10%;
    gap: 50px;
}

.projectsSmall .cards {
    margin: 0px 15px;
    gap: 15px;
}

.button {
    display: flex;
    justify-content: center;
}
