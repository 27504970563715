.base,
.baseSmall {
    display: flex;
    flex-direction: column;
}

.base {
    width: 96%;
    margin: 4% 0%;
    padding-bottom: 4%;
    gap: 50px;
}

.baseSmall {
    width: calc(100% - 50px);
    margin-bottom: 25px;
    gap: 25px;
    padding-bottom: 25px;
}

h2 {
    padding-bottom: 2%;
}

p {
    padding-bottom: 0.5%;
}