.card,
.cardSmall,
.cardExtraSmall,
.rowCard,
.rowCardSmall,
.photoCard,
.photoCardSmall,
.photoCardExtraSmall,
.linksCard,
.linksCardSmall {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: var(--buttons-secondary);
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
}

.card,
.cardSmall,
.rowCard,
.photoCard,
.photoCardSmall,
.linksCard {
    padding: 50px;
    gap: 50px;
    flex: 1 0 0;
    border-radius: 20px;
}

.cardExtraSmall,
photoCardExtraSmall {
    gap: 10px;
}

.linksCard,
.linksCardSmall {
    flex: 0;
    justify-content: space-between;
}

.linksCard h2,
.linksCardSmall h2 {
    margin: 0%;
    padding: 0%;
}

.linksCardSmall h2 {
    font-size: var(--body);
}

.links {
    display: flex;
    gap: 50px;
    flex-grow: 1;
    justify-content: center;
}

.linksCardSmall .links {
    gap: 10px;
}

.photoCard,
.photoCardSmall {
    flex: 0;
    align-self: center;
}

.cardSmall,
.cardExtraSmall {
    flex-direction: column;
}

.photoCardSmall,
.photoCardExtraSmall {
    flex-direction: column-reverse;
}

.photoCardExtraSmall,
.linksCardSmall {
    padding: 20px;
    border-radius: 20px;
}

.cardExtraSmall {
    gap: 10px;
    padding: 15px;
    border-radius: 15px;
}

.rowCard,
.rowCardSmall {
    display: inline-flex;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: unset;
}

.rowCard {
    max-width: 400px;
    aspect-ratio: 700 / 350;
    padding: 20px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 50px;
    width: 20%;
    min-width: 300px;
}

.rowCardSmall {
    gap: 20px;
    padding: 10px;
    border-radius: 10px;
}

.rowCard:hover,
.rowCardSmall:hover {
    background: var(--buttons-primary);
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
}

.rowCard:hover {
    border: 5px solid var(--accent);
}

.rowCardSmall:hover {
    border: 1px solid var(--accent);
}

.rowCard:hover .nugget,
.rowCardSmall:hover .nugget {
    background: var(--accent);
}

.video {
    display: flex;
    flex-grow: 1;
    align-self: stretch;
    border-radius: 10px;
    background: rgba(126, 131, 119, 0.2);
}

video {
    width: 100%;
    overflow: hidden;
}

.img {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    aspect-ratio: 1 / 1;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.photo {
    max-width: 600px;
    aspect-ratio: 1 / 1;
}

.base .photo {
    aspect-ratio: 200 / 100;
}

.cardTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    margin-top: -26px;
    background: rgba(217, 217, 217, 0.5);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border-radius: 0px 0px 10px 10px;
}

.rowCardSmall .cardTitle {
    margin-top: -20px;
}

p {
    display: inline;
    margin: unset;
}

.cardDetails {
    display: flex;
    flex-grow: 1;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    overflow-y: overlay;
}

.cardExtraSmall .cardDetails {
    gap: 20px;
    padding: 10px;
}

.cardSmall .cardDetails {
    flex-direction: row;
    padding: 0%;
    gap: 50px;
}

.photoCard .cardDetails,
.photoCardSmall .cardDetails,
.photoCardExtraSmall .cardDetails {
    align-self: center;
}

.cardInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.cardSmall .cardInfo {
    justify-content: space-between;
}

.cardInfo h2 {
    margin: 0%;
}

.nuggets {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px;
    align-self: stretch;
    flex-wrap: wrap;
}

.rowCardSmall .nuggets {
    align-items: center;
    align-self: center;
}

.nuggets div {
    background: var(--buttons-primary);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.rowCard:hover .nuggets div,
.rowCardSmall:hover .nuggets div {
    background: var(--buttons-secondary);
}
